<template>
  <div class="modal-menu">
    <!-- prettier-ignore -->
    <div class="modal fade" id="modalMenu"
      aria-labelledby="modalMenu" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable my-0">
        <div class="modal-content">
          <div class="modal-header p-2">
            <div class="modal-title d-flex">
              <h5 class="subtitle ms-1">Menú</h5>
            </div>
            <button class="icon-cancel p-0" type="button"
              data-bs-dismiss="modal" aria-label="Close" 
              ref="closeMenu"></button>
            <button type="button" class="btn position-absolute p-0" style="right: 16px"
              data-bs-dismiss="modal" aria-label="Close">
                <span class="material-symbols-outlined">cancel</span>
            </button>
          </div>  <!-- End modal-header -->

          <div class="modal-body px-2 py-0">
            <div class="accordion" id="accordionModal">
              <div class="accordion-item"
                v-for="(family, index) in families"
                :key="index"
                :style="`${
                  family.categories.length === 0 ? 'display: none' : ''
                }`"
              >
                <span class="accordion-header" :id="`menu-heading-${index}`">
                  <!-- Get the first element of array result of family id split -->
                  <!-- id.split('-')[0] = "xxxxxxxx"-xxxx-xxxx-xxxx-xxxxxxxxxxxx -->
                  <button class="accordion-button collapsed ps-0"
                    type="button" data-bs-toggle="collapse"
                    :data-bs-target="`#collapse${family.id}`"
                    aria-expanded="false" aria-controls="collapseOne"
                  >{{ family.name }}</button>
                </span>

                <!-- Collapsed area with subcategories -->
                <div
                  :id="`collapse${family.id}`"
                  class="accordion-collapse collapse"
                  :aria-labelledby="`menu-heading-${index}`"
                  data-bs-parent="#accordionModal"
                >
                  <div class="accordion-body pt-0 pb-0">
                    <ul>
                      <li v-for="(subcategory, index) in family.categories"
                        :key="index" >
                        <a :href="`#cat${subcategory.id}`"
                          @click="closeMenu"
                        >{{ subcategory.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>  <!-- End accordion item -->
            </div>
          </div> <!-- End modal-body -->

          <div class="modal-footer justify-content-center p-2">
          </div>  <!-- End modal-footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuModal",
  mounted() {
    console.log(this.families);
  },
  props: {
    families: Array,
  },
  methods: {
    closeMenu: function () {
      this.$refs.closeMenu.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-content {
    background: var(--secondary-bg);
    border: 0;
    img {
      width: 28px;
    }
  }
  &-body {
    button {
      background: var(--secondary-bg);
      color: var(--primary-font-cl);
    }
  }
  &-header {
    border: 0;
    button {
      border: 0;
      background: transparent;
      font-size: 2.8rem;
      color: var(--primary-font-cl);
    }
  }
  &-dialog {
    height: 100%;
    display: flex;
    align-items: flex-end;
    border-radius: 10px;
  }
  &-footer {
    border: 0;
  }
}
.accordion {
  &-button {
    font-size: $ft-xs;
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
  &-item {
    border-bottom: 1px solid var(--primary-font-cl);
    background: var(--secondary-bg);
  }
  &-body li {
    padding-bottom: 5px;
    a {
      color: var(--secondary-font-cl);
    }
  }
}
@media (min-width: $tablet-width) {
  #modalMenu .modal {
    &-dialog {
      width: 70%;
    }
    &-content {
      height: 50vh;
    }
  }
}
</style>